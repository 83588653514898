<template>
    <div class="flex-1">
        <div class="flex items-center justify-between gap-4 mb-4 wide:mb-8 mt-2">
            <div v-if="has_checks" class="text-lg capitalize font-medium px-3 text-base-content-800">checks available
                in package</div>
                <div class="flex">
                    <div class="px-2">
                        <dropdown
                            v-model="selectedData"  
                            width="min-content" 
                            min-width="200px" 
                            :placeholder="'Sort By :'  + selectedData"
                            @input="(value) => handleFilterActivity(value)" 
                            :config="{ label: 'title', trackBy: 'value' }" 
                            :options="options.shortKey"
                        >
                        </dropdown>
                    </div>
                    <Button :disabled="(this.$route.params.action === 'view')"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"
                        v-if="has_checks && checkPermission('check.read') && checkPermission('package.edit') && (this.$route.params.action !== 'view')" text="add check"
                        class="inline btn-primary" @click="openAddCheckModal" />
                </div>
            </div>

        <div class="rounded-md relative">
            <modal-content ref="add-check-to-package" :showFooter="false" :max-width="480" name="addcheck"
                title="Add Checks to Package" @hidden="onHidden">
                <template #content>
                    <div class="flex h-96 pb-4 overflow-y-auto justify-center items-center" v-if="fetchingAllChecks">
                        <Loader />
                    </div>
                    <div v-else class="flex flex-col h-96 pb-4 overflow-y-auto">
                        <ListView
                            :showSearch="true"
                            :listItems="unaddedChecks"
                            class="max-w-full"
                            :showBorder="false"
                            @handleFilterResult="handleFilterResult($event)"
                            :filters="addFieldFilters"
                        >
                            <template #item="{ item }">
                                <div class="w-full py-1 text-sm flex justify-between items-center border-box-stroke"
                                    style="border-bottom: solid 1px var(--theme-color-box-stroke)">
                                    <div class="truncate border-box-stroke" v-tippy :content="item.name">
                                        {{ item.name }}
                                    </div>
                                    <div>
                                        <button :disabled="assigningPackages"
                                            class="bg-primary hover:bg-primary-focus font-bold text-white-text cursor-pointer rounded-md px-4 py-1.5 text-xs"
                                            @click="addCheckToPackage(item)">Add</button>
                                    </div>
                                </div>                                
                            </template>
                        </ListView>
                    </div>
                </template>
                <!-- <template #footer>
                    <div class="flex w-full"></div>
                </template> -->
            </modal-content>
            <div v-if="checksFetch.started"
                class="max-auto flex flex-col gap-5 justify-center items-center w-full h-80 rounded-lg ring-2-gray-500">
                <Loader />
                Loading Checks...
            </div>
            <div v-else>
                <div v-if="!has_checks" class="w-full flex justify-center items-center flex-1">
                    <Empty text="This package does not have any active check assigned" classList="text-lg"
                        :showBtn="checkPermission('package.read') && checkPermission('package.edit') && checkPermission('check.read')"
                        btnText="Assign check" @btnClick="openAddCheckModal" />
                </div>
                <!-- <FormsList 
                    :formsList="packageChecks" 
                    :disabled="querydata == 'view'"
                    @removeCheck="removeCheckFromPackage($event)"
                /> -->
                <div class="bg-gray-100 rounded-2xl p-2">
                    <draggable   
                        v-model="packageChecks"
                        @start="isDragging = true"
                        @end="stopDragging($event)"
                        @change="onReorder($event)"
                        :group="{ name: 'check', pull: 'clone', put: true }"
                        class="sortable-list"
                        :disabled="selectedData != 'Short By : Custom List'"
                    >
                        <div v-for="(check, index) in packageChecks" :key="check.id"
                            class="list-group-item child item"
                        >
                            <template>
                                <PackagesTable
                                    :check="check"
                                    :index="index"
                                    :ISEDITPERMISSIONS="ISEDITPERMISSIONS"
                                    @removeCheck="removeCheckFromPackage($event)"
                                    :disabled="querydata == 'view'"
                                    @handleFieldReorder= "handleFieldReorder($event)"
                                    @updateFieldLabel ="updateFieldLabel(check)"
                                />
                            </template>  
                        </div>
                    </draggable>
                </div>
            </div>
        </div>

        <!-- delete popup confirm -->

        <modal-confirm title="Are you sure?" message="Please confirm you're about to remove this check."
            ref="confirm-popup"> </modal-confirm>
    </div>
</template>
<script>
import Button from "@/components/button";
import modalContent from "@shared/modal-content";
import axios from "@/axios";
import { mapActions, mapState, mapGetters } from "vuex";
import { getChecksList, getPackagesCheck } from "@/modules/checks/services.js";
import Loader from "@shared/loader";
import ListView from "@shared/components/list-view";
const modalConfirm = () => import("@/components/modal-confirm");
import { getRandomColors, nameToShort } from "@/plugins/functions";
import Empty from "@shared/empty"
import { checkPermission } from "@shared/utils/functions"
// import FormsList from "@shared/components/forms-list"
import PackagesTable from "@shared/components/packages-table"
import dropdown from "@shared/components/dropdown-base";
import draggable from 'vuedraggable';

export default {
    name: "checks",
    components: {
        Button,
        Loader,
        "modal-content": modalContent,
        ListView,
        modalConfirm,
        Empty,
        // FormsList
        PackagesTable,
        dropdown,
        draggable

    },
    props: [
        "componentData",
        "isUserPermission",
    ],
    data: () => ({
        checksFetch: {
            started: false,
            no_data: false,
            has_data: true,
            errored: false,
        },
        confirmRemovePackageFromCheck: false,
        checksData: null,
        isLoading: false,
        values: ["hello", "dad"],
        currentPage: 1,
        allChecks: [],
        searchValue: "",
        packageChecks: [],
        getTotalPages: 3,
        assigningPackages: false,
        fetchingAllChecks: false,
        querydata:null,
        fieldTypeFilter: [],
        isEntityTypesLoading: false,
        options: {
            // TODO; get options for shorting
            shortKey: [
                { title: "Custom List", value: "CUSTOM" },
                { title: "A to Z", value: "ASC" },
                { title: "Z to A", value: "DESC" },
            ],
        },
        selectedData: '',
    }),
    methods: {
        ...mapActions(["fetchEntityTypeList"]),
        checkPermission,
        getRandomColors,
        nameToShort,
        async fetchAllPackageChecks() {
            this.checksFetch.started = true;
            const package_id = this.$route.params.packageName;
            const checks  = await getPackagesCheck(package_id);
            this.packageChecks = checks.checks;
            if(checks.check_order_type == "CUSTOM")
            { 
                this.selectedData = "Short By : Custom List"
            }
            else if(checks.check_order_type == "ASC"){
                this.selectedData = "Short By : A to Z"
            }
            else if(checks.check_order_type == "DESC"){
                this.selectedData = "Short By : Z to A"
            }
            this.checksFetch.started = false;
        },
        askRemoveConfirm(check) {
            check.confirmRemovePackageFromCheck = true;
            this.$set(check, "confirmRemovePackageFromCheck", true);
        },
        async addCheckToPackage(check) {
            this.assigningPackages = true;
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}/check/${check.id}`;

            try {
                await axios.post(url);
                this.$toast.success("Check attached to package.");
                this.checks = this.checks.filter((e) => {
                    return e.id != check.id;
                });
                check.check_id = check.id;
                this.packageChecks.push(check);
            } catch (error) {
                // this.assigningPackages = false;
                this.$toast.error(error?.response?.data?.detail || "Could not attach check to package.");
            }

            this.assigningPackages = false;
        },

        async removeCheckFromPackage(check) {
            if (this.$route.params.action == 'view') {
                return
            }
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Check  will be deleted permanently. Please confirm to continue deleting this check.",
            });
            if (promise) {
                const package_id = this.$route.params.packageName;
                const url = `/screening-package/${package_id}/check/${check.check_id}`;

                try {
                    await axios.delete(url);
                    this.$toast.success("Check removed from package.");
                } catch (error) {
                    this.$toast.warning("Could not remove check from package.");
                }
            }
            this.$refs["confirm-popup"].close();
            // this.checks.push(check)
            await this.fetchAllPackageChecks();
        },
        async openAddCheckModal() {
            this.$refs["add-check-to-package"].showModal();
            this.fetchingAllChecks = true
            await this.fetchChecksList();
            this.fetchingAllChecks = false
        },
        async fetchPackageChecks() { },
        onHidden() {
            this.searchValue = '';
            this.fieldTypeFilter = [];
            console.log('help :>> ', { 'help': "help" });
        },
        async fetchChecksList() {
            const tenant = this.$store.getters.getTenantId;
            let payload = {
                entity_type: this.getPackage?.entity_types?.map(i=>(i.id)),
                strict_entity_type_filter: false,
            };
            let checksData = await getChecksList(tenant, 1000, {
                params: payload,
                paramsSerializer: { indexes: null } 
            });
            checksData.map((el) => {
                if (el.parameters) {
                    let data = JSON.parse(el.parameters);
                    return { ...el, parameters: data.parameters };
                } else {
                    return { ...el };
                }
            });
            this.allChecks = checksData;
            // this.columns = columns;
            this.isLoading = false;
        },
        async fetchEntityTypeOptions() {
            this.isEntityTypesLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList()
            }
            this.isEntityTypesLoading = false;
        },

        handleFilterResult(search) {
            return this.searchValue = search;
        },
        handleFilterActivity(value) {
            let payload;
            this.selectedData = "Short By : " + value.title
            if (value.value === "ASC") {
                payload = {"check_order_type": "ASC"}
            } else if (value.value === "DESC") {
               payload ={"check_order_type": "DESC"}
            } else if (value.value === "CUSTOM") {
               return
            }
            this.fetchSortOptions(payload);
        },
        async fetchSortOptions(value) {
            console.log("value ", value)
            // this.fetchingSortOptions = true;
            const package_id = this.$route.params.packageName;
            try {
                let url = `/screening-package/${package_id}/checks/reorder`;
                let { data } = await axios.post(url, value);
                this.$toast.success(data.message || "Updated Sucessfully.");
                await this.fetchAllPackageChecks();

            } catch (error) {
                console.log(error, "error>>>>>");
            }
        },
        
        startDragging() {
            this.isDragging = true;
        },
        stopDragging(e) {
            console.log('Drag ended:', e);
            this.isDragging = false;
        },
        async onReorder(e) {
            if (e.moved) {
                const oldIndex = e.moved.oldIndex;
                const newIndex = e.moved.newIndex;
                const removed = this.packageChecks.splice(oldIndex, 0);  

                this.packageChecks.splice(newIndex, 0, ...removed);   

                const section_list = [];
                this.packageChecks.forEach((item, index) => {
                    item.order = index;
                    section_list.push({ section_id: item.id, order: index + 1 });
                });
                let checkIds = this.packageChecks.map(item => item.check_id);
                let payload = {
                    "check_order_type": "CUSTOM",
                    "checks": checkIds
                };
                await this.fetchSortOptions(payload);
            }
        },
        async updateFieldLabel(check){
            console.log("check", check)
            this.assigningPackages = true;
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}/check/${check.check_id}`;
            let payload = { 
                label: check.label,
                description: check.description
            }          
            try {
                await axios.put(url, payload);
                this.$toast.success("Updated Sucessfully.");
            } catch (error) {
                // this.assigningPackages = false;
                this.$toast.error(error?.response?.data?.detail || "Could not attach check to package.");
            }

            this.assigningPackages = false;
        }
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
        }),
        ...mapGetters(["getPackage"]),
        has_checks() {
            return this.packageChecks.length >= 1;
        },
        checks() {
            return this.allChecks.map((e) => {
                return { ...e, confirmRemovePackageFromCheck: false };
            });
        },
        unaddedChecks() {
            // if (this.searchValue) {
            // let filteredChecks = 

            return this.checks.filter(fil => fil?.name?.toLowerCase().includes(this.searchValue?.toLowerCase()) && !this.packageChecks.some(el => el.check_id === fil.id))

            //  filteredChecks
            //     return this.checks.filter((e) => {
            //         for (let i of this.packageChecks) {
            //             if (i.id === e.id) {
            //                 return false;
            //             }
            //         }
            //         if (e?.name?.toLowerCase().includes(this.searchValue?.toLowerCase())) return true;
            //     });
            // } else {
            //     return this.checks.filter((e) => {
            //         for (let i of this.packageChecks) {
            //             if (i.id === e.id) {
            //                 return false;
            //             }
            //         }
            //         return true;
            //     });
            // }
        },
        addFieldFilters() {
            const list = []
            const entityTypeFilter = {
                id: 'entity_type',
                options: this.entityTypeOptions,
                value: this.fieldTypeFilter,
                // check if any selected type mathches any element's type:
                check: (el) => {
                    if (!this.fieldTypeFilter.length) return true
                    return el.entity_types.length == this.fieldTypeFilter.length && el.entity_types.every((type) => {
                        return !!this.fieldTypeFilter.find((filter) => filter.id === type.id)
                    })
                },
                reset: (payload) => {
                    this.fieldTypeFilter = payload || [];
                },
                props: {
                    variant: 'alt',
                    config: {
                        label: 'name',
                        trackBy: 'id',
                    },
                },
            }
            list.push(entityTypeFilter)
            return list
        },
        ISEDITPERMISSIONS() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS)
            } else {
                return true
            }
        },
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    async mounted() {
        this.querydata=this.$route.params.action
        this.checksFetch.started = true;
        this.isLoading = true;
        this.checksData = this.componentData;
        this.isLoading = false;
        this.fetchEntityTypeOptions();
        await this.fetchAllPackageChecks();
    },
};
</script>
<style scoped lang="scss">
::v-deep {
    // .multiselect__tags {
    //     min-height: 0px !important;
    // }

    // .multiselect__content-wrapper {
    //     position: relative !important;
    // }

    .multiselect__option {
        min-height: 0 !important;
    }

    .modalContent__content {
        display: flex;
        flex-direction: column;
    }

    button:disabled {
        opacity: 50% !important;
    }
    .dropdown:not(.dropdown--custom-styles) {
        max-height: 37px !important;
        min-height: 37px !important;
    }

}</style>
